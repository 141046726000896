export const Counter = (props) => {
    return (
        <div id='counter' className='text-center'>
            <div className="counter-container">
            {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.number}-${i}`} className='counter'>
                  <h2 data-target={d.number} className="count counter-number">0</h2>
                  <h3 className="counter-text">{d.text}</h3>
                </div>
              ))
            : 'Loading...'}
            </div>
        </div>
    )
}