export const Features = (props) => {
  return (
    <div id='distributivni-program' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>{props.data ? props.data.features_name : 'Loading'}</h2>
        </div>
        <div className='distr-images'>
          {props.data
            ? props.data.single.map((d, i) => (
                <div key={`${d.title}-${i}`} className='single-img'>
                  {' '}
                  <img src={d.image} alt={d.image_alt} title={d.img_title} />
                </div>
              ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  )
}
