export const About = (props) => {
  const img = props.data ? props.data.img_path : 'loading...'
  const about_style = {
    backgroundColor: "081F2D",    
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundImage: "url(" + img + ")"
  };
  return (
    <div id="o-nama">
      <div className="about-section-wrapper">
        <div className="about-section-img" style={about_style}>
          {/* <img src={props.data ? props.data.img_path : "loading..."} className="img-responsive" alt={props.data ? props.data.img_alt : "loading..."} title={props.data ? props.data.img_title : "loading..."} /> */}
        </div>
        <div className="about-text">
          <h2>{props.data ? props.data.title : "loading..."}</h2>
          <div className="list-style">
              <ul>
                {props.data
                  ? props.data.List.map((d, i) => (
                      <li key={`${d}-${i}`}>{d}</li>
                    ))
                  : "loading"}
              </ul>
          </div>
          <a
                  href={props.data ? props.data.button_link : 'Loading'}
                  className='btn btn-custom btn-lg page-scroll'
                  target={props.data ? props.data.button_link_target : 'Loading'}
                >
                  {props.data ? props.data.button : 'Loading'}
                </a>
        </div>
      </div>
    </div>
  );
};
