import { useState } from 'react'
import emailjs from 'emailjs-com'

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'service_t751fzo', 'template_yxh6nwb', e.target, 'd91Q98eFDzJ8l6vQi'
      )
      .then(
        (result) => {
          console.log(result.text)
          let successMessage = document.getElementById("success")
          successMessage.innerHTML += "Poruka je uspešno poslata. <br />Uskoro ćemo vas kontaktirati."
          let kontaktForma = document.getElementById("kontakt-forma")
          kontaktForma.remove()
          let sectionTitleInfo = document.getElementById("section-title-info")
          sectionTitleInfo.remove()
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <div>
      <div id='kontakt'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Kontakt</h2>
                <p id="section-title-info">
                  Popunite kontakt formu i mi ćemo Vam odgovoriti u najkraćem roku.
                </p>
              </div>
              <form id="kontakt-forma" name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Ime'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Poruka'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Pošalji
                </button>
              </form>
              <div id='success' style={{textAlign: "center"}}></div>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
          <h3>Kontakt podaci</h3>
            <div className="contact-items">
              <div className='contact-item'>
                <p>
                  <span>
                    <i className='fa fa-map-marker'></i> Adresa
                  </span>
                  {props.data ? props.data.address : 'loading'}
                </p>
              </div>
              <div className='contact-item'>
                <p>
                  <span>
                    <i className='fa fa-phone'></i> Telefon
                  </span>{' '}
                  <a href={props.data ? props.data.phone_plain : 'loading'}>
                  {props.data ? props.data.phone : 'loading'}</a>
                </p>
              </div>
              <div className='contact-item'>
                <p>
                  <span>
                    <i className='fa fa-envelope-o'></i> E-mail
                  </span>{' '}
                  <a href={props.data ? props.data.email_plain : 'loading'}>
                  {props.data ? props.data.email : 'loading'}</a>
                </p>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'} target="_blank" rel="noreferrer">
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : '/'} target="_blank" rel="noreferrer">
                      <i className='fa fa-instagram'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'} target="_blank" rel="noreferrer">
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2022 AlpaNautic
          </p>
        </div>
      </div>
    </div>
  )
}
